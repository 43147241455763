import React, { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';

import analytics from '@/utils/analytics';
import RateCalculator, { sortByTrueRate, RateCalculatorResult } from '@/models/rate-calculator';
import { RouteDetails } from '@/components/RouteOption';
import { LocationType } from '@/maps';
import Header from '@/components/Header';
import Options from '@/components/Options';
import RatesContainer from '@/components/RatesContainer';

function App() {
  const tableRef = useRef<HTMLTableElement>(null);
  const [rates, setRates] = useState<RateCalculatorResult[]>([]);
  const [showNumbers, setShowNumbers] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Init Analytics
  useEffect(() => {
    analytics.init();
  }, []);

  const handleCalculate = async (origin: LocationType, options: RouteDetails[]) => {
    const rates: RateCalculatorResult[] = [];
    setError(null);
    setLoading(true);
    setShowNumbers(true);
    tableRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    for (const option of options) {
      try {
        const rate = await new RateCalculator(origin, option).calculate();
        rates.push(rate);
      } catch (e) {
        setError('Some routes could not be calculated. Please adjust the values or try again.');
      }
    }

    setRates(rates.sort(sortByTrueRate));
    setLoading(false);
  };

  return (
    <div className="App">
      <Container disableGutters maxWidth="xl" component="header" sx={{ my: 4 }}>
        <Header />
      </Container>
      <Container disableGutters maxWidth="xl" component="main">
        <Options onCalculate={handleCalculate} />
      </Container>
      {showNumbers && (
        <Container disableGutters maxWidth="xl" component="div" sx={{ mt: 4 }} ref={tableRef}>
          <RatesContainer rates={rates} isLoading={loading} error={error} />
        </Container>
      )}
    </div>
  );
}

export default App;
