class GeocoderClass {
  private instance: google.maps.Geocoder;

  constructor() {
    this.instance = new google.maps.Geocoder();
  }

  async geocode(address: string, callback: (result: google.maps.GeocoderResult) => void) {
    const response = await this.instance.geocode({ address });
    callback(response.results[0]);
  }

  async reverse_geocode(location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
    const response = await this.instance.geocode({ location });
    return response.results[0];
  }
}

export const GeocoderService = new GeocoderClass();
