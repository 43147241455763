import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash.throttle';

import { AutocompleteService, LocationService } from '@/maps';
import { INITIAL_PLACE_PREDICTION } from './constants';
import { PlaceSearchDropdownProps } from './interface';
import PlaceOption from './PlaceOption';

function PlaceSearchDropdown({
  error,
  label,
  sx,
  onPlaceSelect,
  value,
}: PlaceSearchDropdownProps): JSX.Element {
  const [selectedAddress, setSelectedAddress] = useState<google.maps.places.AutocompletePrediction>(
    value ? LocationService.to_automplete(value) : INITIAL_PLACE_PREDICTION,
  );
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const fetchAutocompleteOptions = useMemo(
    () =>
      throttle(
        (
          input: string,
          callback: (a: google.maps.places.AutocompletePrediction[] | null) => void,
        ) => AutocompleteService.getPlacePredictions(input, callback),
        200,
      ),
    [],
  );

  const handleAutocompleteResponse = (
    results: google.maps.places.AutocompletePrediction[] | null,
  ) => {
    let options: google.maps.places.AutocompletePrediction[] = [];

    if (selectedAddress) {
      options = [selectedAddress];
    }

    if (results) {
      options = [...options, ...results];
    }

    setOptions(options);
  };

  useEffect(() => {
    if (inputValue === '') {
      setOptions(selectedAddress ? [selectedAddress] : []);
      return undefined;
    }

    fetchAutocompleteOptions(inputValue, handleAutocompleteResponse);
  }, [selectedAddress, inputValue, fetchAutocompleteOptions]);

  useEffect(() => {
    if (value) {
      const autocompleteValue = LocationService.to_automplete(value);
      onPlaceSelect(value);
      setOptions([autocompleteValue]);
      setSelectedAddress(autocompleteValue);
    }
  }, [value]);

  return (
    <Autocomplete
      autoComplete
      disableClearable
      filterSelectedOptions
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      options={options}
      value={selectedAddress}
      size="small"
      onChange={(_: any, newValue: google.maps.places.AutocompletePrediction) => {
        if (selectedAddress.place_id !== newValue.place_id) {
          setOptions([newValue, ...options]);
          setSelectedAddress(newValue);
          onPlaceSelect(LocationService.from_autocomplete(newValue));
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText={inputValue ? 'No Places Found' : 'Search for a Place'}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth error={Boolean(error)} helperText={error} />
      )}
      renderOption={(props, option) => <PlaceOption {...props} option={option} />}
      sx={sx}
    />
  );
}

export default PlaceSearchDropdown;
