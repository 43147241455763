class DistanceClass {
  private instance: google.maps.DistanceMatrixService;

  constructor() {
    this.instance = new google.maps.DistanceMatrixService();
  }

  async calculate_distance(
    origin: string,
    destination: string,
  ): Promise<google.maps.DistanceMatrixResponseElement> {
    const response = await this.instance.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: google.maps.TravelMode.DRIVING,
    });

    return response.rows[0].elements[0];
  }
}

export const DistanceService = new DistanceClass();
