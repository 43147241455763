import { DistanceService, DirectionsService, LocationType } from '@/maps';
import { RouteDetails } from '@/components/RouteOption';
import { sendEvent } from '@/utils/analytics';

export interface RateCalculatorResult {
  id: string;
  dho: string;
  pickup: string;
  dropoff: string;
  directions: google.maps.DirectionsResult | null;
  leg_distance: number[];
  total_miles: number;
  true_rate: number;
  rate: number;
  time: string;
  title: string;
}

enum ERRORS {
  INCOMPLETE_DATA = 'INCOMPLETE_DATA',
}

class RateCalculator {
  constructor(private origin: LocationType, private details: RouteDetails) {}

  async calculate(): Promise<RateCalculatorResult> {
    if (!this.origin || !this.details.pickup || !this.details.dropoff) {
      return this.handle_error(ERRORS.INCOMPLETE_DATA);
    }

    try {
      const driving_time = await this.calculate_driving_time(
        this.origin,
        this.details.pickup,
        this.details.dropoff,
      );
      const directions = await this.calculate_directions(
        this.origin,
        this.details.pickup,
        this.details.dropoff,
      );
      const true_rate = this.calculate_true_rate(driving_time.distance, this.details.rate);

      // Analytics
      sendEvent({
        category: 'Submitted Form',
        action: 'Calculating True Rate',
        label: `${this.origin.address} -> ${this.details.pickup.address} -> ${this.details.dropoff.address} - Miles: ${driving_time.distance}, Rate: ${this.details.rate}`,
        value: true_rate,
      });

      return {
        id: this.details.id,
        title: this.details.title,
        dho: this.origin.address,
        pickup: this.details.pickup.address,
        dropoff: this.details.dropoff.address,
        directions,
        rate: this.details.rate,
        total_miles: driving_time.distance,
        true_rate,
        leg_distance: driving_time.leg_distance,
        time: driving_time.duration,
      };
    } catch (e) {
      return this.handle_error(e);
    }
  }

  async calculate_driving_time(origin: LocationType, pickup: LocationType, dropoff: LocationType) {
    const origin_to_pickup = await this.calculate_distance(origin.address, pickup.address);
    const pickup_to_destination = await this.calculate_distance(pickup.address, dropoff.address);

    // conversion to miles
    return {
      distance: (origin_to_pickup.distance + pickup_to_destination.distance) * 0.000621371192,
      leg_distance: [
        origin_to_pickup.distance * 0.000621371192,
        pickup_to_destination.distance * 0.000621371192,
      ],
      duration: this.combine_duration(origin_to_pickup.duration + pickup_to_destination.duration),
    };
  }

  // returns in metres
  async calculate_distance(
    origin: string,
    destination: string,
  ): Promise<{ distance: number; duration: number }> {
    const { distance, duration } = await DistanceService.calculate_distance(origin, destination);
    return { distance: distance.value, duration: duration.value };
  }

  async calculate_directions(origin: LocationType, pickup: LocationType, dropoff: LocationType) {
    return await DirectionsService.getDirections(origin.address, pickup.address, dropoff.address);
  }

  calculate_true_rate(miles: number, rate: number): number {
    return rate / miles;
  }

  combine_duration(duration: number): string {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`.trim();
  }

  handle_error(error: any): RateCalculatorResult {
    // Analytics
    sendEvent({
      category: 'Submitted Form',
      action: 'Error Calculating True Rate',
      label: `${this.origin.address} -> ${this.details.pickup?.address} -> ${this.details.dropoff?.address}`,
    });

    throw new Error(error);
  }
}

export function sortByTrueRate(a: RateCalculatorResult, b: RateCalculatorResult) {
  return b.true_rate - a.true_rate;
}

export default RateCalculator;
