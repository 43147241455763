import React from 'react';
import Typography from '@mui/material/Typography';

function Header(): JSX.Element {
  return (
    <>
      <Typography component="h1" variant="h3" align="center" color="text.primary" gutterBottom>
        The Rate Per Mile is an illusion! Find out why
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary" component="p">
        Do you consider your deadhead miles in your RPM calculation?
      </Typography>
    </>
  );
}

export default Header;
