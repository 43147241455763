import { object, string, ValidationError, setLocale } from 'yup';
import { LocationType } from '@/maps';

setLocale({
  mixed: {
    notType: '${path} is required',
  },
});

const originSchema = object({
  id: string().required(),
  address: string().required(),
});

class OriginOptionValidator {
  error: string | undefined = undefined;

  async validate(values: LocationType | null) {
    try {
      this.error = undefined;
      await originSchema.validate(values, {
        strict: true,
      });
    } catch (err) {
      this.error = 'Origin is required.';
    }
  }
}

export default OriginOptionValidator;
