import { LocationType } from './interface';
import { RouteDetails } from '@/components/RouteOption';

class DirectionsServiceClass {
  private instance: google.maps.DirectionsService;

  constructor() {
    this.instance = new google.maps.DirectionsService();
  }

  async getDirections(
    origin: string,
    pickup?: string,
    destination?: string,
  ): Promise<google.maps.DirectionsResult | null> {
    if (!pickup || !destination) {
      return null;
    }

    const response = await this.instance.route({
      origin,
      destination,
      provideRouteAlternatives: true,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: [
        {
          location: pickup,
          stopover: true,
        },
      ],
    });

    return response;
  }
}

export const DirectionsService = new DirectionsServiceClass();
