import * as React from 'react';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';

interface PlaceOptionProps extends React.HTMLAttributes<HTMLLIElement> {
  option: google.maps.places.AutocompletePrediction;
}

function PlaceOption({ option, ...props }: PlaceOptionProps) {
  const matches = option.structured_formatting.main_text_matched_substrings;
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item>
          <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
}

export default PlaceOption;
