import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';

import StartLocation from '@/components/StartLocation';
import RouteOption, { RouteDetails } from '@/components/RouteOption';

import { OptionsProps } from './interface';
import { sendEvent } from '@/utils/analytics';
import { LocationType } from '@/maps';
import OriginValidator from '@/models/origin-validator';
import RouteOptionValidator from '@/models/route-option-validator';

function Options({ onCalculate }: OptionsProps) {
  const [origin, setOrigin] = useState<LocationType | null>(null);
  const [originError, setOriginError] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<RouteDetails[]>([
    { id: uuidv4(), title: 'Option 1', pickup: null, dropoff: null, rate: 0 },
    { id: uuidv4(), title: 'Option 2', pickup: null, dropoff: null, rate: 0 },
  ]);
  const [optionErrors, setOptionErrors] = useState<Record<string, Record<string, string>>>({});

  const handleOptionUpdate = (id: string) => (option: Omit<RouteDetails, 'id' | 'title'>) => {
    const indexToUpdate = options.findIndex((option) => option.id === id);

    if (indexToUpdate === -1) {
      console.error('cannot update option: not found');
      return;
    }

    const updatedOptions = [
      ...options.slice(0, indexToUpdate),
      { id, title: options[indexToUpdate].title, ...option },
      ...options.slice(indexToUpdate + 1),
    ];

    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    sendEvent({
      category: 'Button',
      action: 'Add option clicked',
      value: options.length + 1,
    });
    setOptions((prev) => [
      ...prev,
      {
        id: uuidv4(),
        title: `Option ${prev.length + 1}`,
        pickup: null,
        dropoff: null,
        rate: 0,
      },
    ]);
  };

  const handleRemoveOption = (id: string) => () => {
    const indexToDelete = options.findIndex((option) => option.id === id);

    if (indexToDelete === -1) {
      console.error('cannot delete option: not found');
      return;
    }

    const updatedOptions = [
      ...options.slice(0, indexToDelete),
      ...options.slice(indexToDelete + 1).map((option, idx) => ({
        ...option,
        title: `Option ${indexToDelete + idx + 1}`,
      })),
    ];

    sendEvent({
      category: 'Button',
      action: 'Remove option clicked',
      value: indexToDelete + 1,
    });
    setOptions(updatedOptions);
  };

  const handleCalculate = async () => {
    const errors: Record<string, Record<string, string>> = {};

    const originValidator = new OriginValidator();
    await originValidator.validate(origin);

    for (const option of options) {
      const validator = new RouteOptionValidator();
      await validator.validate(option);

      if (validator.errors) {
        errors[option.id] = validator.errors;
      }
    }

    setOriginError(originValidator.error);
    setOptionErrors(errors);

    if (!Object.keys(errors).length && !originValidator.error) {
      sendEvent({
        category: 'Button',
        action: 'Calculate Button Clicked',
      });
      onCalculate(origin!, options);
    }
  };

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid container item sx={{ mb: 2 }}>
        <Grid container item xs={12} justifyContent="center" sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <StartLocation onPlaceSelect={setOrigin} error={originError} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="span" sx={{ mr: 2 }}>
            Your Options
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAddOption}
            sx={{
              background: '#E7EAEE',
              border: 'none',
              color: '#606874',
              fontSize: 12,
              minWidth: 'initial',
              textTransform: 'initial',
              '&:hover': {
                background: '#C8CDD5',
                border: 'none',
              },
            }}
          >
            Add
          </Button>
        </Grid>
        {options.map((option, idx) => (
          <Grid item key={option.id} xs={12} md={4} sx={{ pr: 2 }}>
            <RouteOption
              idx={idx}
              title={option.title}
              origin={origin}
              onChange={handleOptionUpdate(option.id)}
              onRemove={handleRemoveOption(option.id)}
              errors={optionErrors[option.id]}
              isRemovable={options.length > 1}
            />
          </Grid>
        ))}
        <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}>
          <Button variant="outlined" onClick={handleCalculate} size="large">
            Calculate
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Options;
