import React from 'react';

import { NumberProps } from './interface';

function Number({ options, suffix, value }: NumberProps): JSX.Element {
  const NumberFormat = new Intl.NumberFormat('en-US', options);
  return (
    <>
      {NumberFormat.format(value)} {suffix}
    </>
  );
}

export default Number;
