import { useState, useEffect } from 'react';

export function useGeolocation() {
  const [state, setState] = useState<GeolocationCoordinates | null>(null);
  let mounted = true;

  const onEvent = ({ coords }: GeolocationPosition) => {
    if (mounted) {
      setState(coords);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onEvent);

    return () => {
      mounted = false;
    };
  }, []);

  return state;
}
