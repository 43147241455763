import { object, string, number, ValidationError, setLocale } from 'yup';
import { RouteDetails } from '@/components/RouteOption';

setLocale({
  mixed: {
    notType: '${path} is required',
  },
});

const optionSchema = object({
  dropoff: object({
    id: string().required(),
    address: string().required(),
  }),
  pickup: object({
    id: string().required(),
    address: string().required(),
  }).required(),
  rate: number().required().positive(),
});

class RouteOptionValidator {
  errors: Record<string, string> | null = null;

  async validate(values: RouteDetails) {
    try {
      this.errors = null;
      await optionSchema.validate(values, {
        strict: true,
        abortEarly: false,
      });
    } catch (err) {
      this.errors = {};

      for (const error of (err as ValidationError).inner) {
        this.errors[error.path ?? 'unknown'] = error.message;
      }
    }
  }
}

export default RouteOptionValidator;
