import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TextWithHeaderProps } from './interface';

function TextWithHeader({
  header,
  headerTypographyProps = {},
  text,
  textTypographyProps = {},
  sx,
}: TextWithHeaderProps): JSX.Element {
  return (
    <Grid container direction="column" sx={sx}>
      <Grid item xs={12}>
        <Typography
          component="span"
          color="text.secondary"
          variant="body2"
          {...headerTypographyProps}
        >
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" variant="h5" {...textTypographyProps}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TextWithHeader;
