class AutocompleteServiceClass {
  private instance: google.maps.places.AutocompleteService;

  constructor() {
    this.instance = new google.maps.places.AutocompleteService();
  }

  async getPlacePredictions(
    input: string,
    callback: (results: google.maps.places.AutocompletePrediction[] | null) => void,
  ) {
    const response = await this.instance.getPlacePredictions({
      input,
      location: new google.maps.LatLng(47.116385, -101.299594),
      radius: 3217070,
    });
    callback(response.predictions);
  }
}

export const AutocompleteService = new AutocompleteServiceClass();
