import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { RouteOptionProps } from './interface';
import { sendEvent } from '@/utils/analytics';
import { LocationType } from '@/maps';
import PlaceSearchDropdown from '@/components/PlaceSearchDropdown';

function RouteOption({
  errors,
  title,
  idx,
  onChange,
  onRemove,
  isRemovable,
}: RouteOptionProps): JSX.Element {
  const [pickup, setPickup] = useState<LocationType | null>(null);
  const [dropoff, setDropoff] = useState<LocationType | null>(null);
  const [rate, setRate] = useState<number>(0);

  useEffect(() => {
    onChange({ pickup, dropoff, rate });
  }, [pickup, dropoff, rate]);

  const onSetPickup = (location: LocationType) => {
    sendEvent({
      category: 'Option Form',
      action: 'Pickup Location Selected',
      label: location.address,
    });

    setPickup(location);
  };

  const onSetDropoff = (location: LocationType) => {
    sendEvent({
      category: 'Option Form',
      action: 'Dropoff Location Selected',
      label: location.address,
    });

    setDropoff(location);
  };

  return (
    <Grid container alignItems="center">
      <Grid container item sx={{ pl: 2, mb: 1 }} justifyContent="space-between" alignItems="center">
        <Typography
          component="p"
          variant="body1"
          color="text.primary"
          textTransform="uppercase"
          fontWeight="500"
        >
          {title}
        </Typography>
        {isRemovable && (
          <IconButton aria-label="remove option" onClick={onRemove} tabIndex={-1}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pl: 2,
          borderLeft: idx && idx % 3 !== 0 ? `1px solid rgba(0, 0, 0, 0.12)` : undefined,
        }}
      >
        <PlaceSearchDropdown
          label="Pickup"
          sx={{ mb: 2 }}
          onPlaceSelect={onSetPickup}
          error={errors?.pickup}
        />
        <PlaceSearchDropdown
          label="Dropoff"
          sx={{ mb: 2 }}
          onPlaceSelect={onSetDropoff}
          error={errors?.dropoff}
        />
        <TextField
          fullWidth
          label="Rate"
          size="small"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{ mb: 2 }}
          error={Boolean(errors?.rate)}
          helperText={errors?.rate}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            sendEvent({
              category: 'Option Form',
              action: 'Rate Set',
              value: Number(event.target.value),
            });
            setRate(Number(event.target.value));
          }}
        />
      </Grid>
    </Grid>
  );
}

export default RouteOption;
