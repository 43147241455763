import React, { useEffect, useState } from 'react';

import { StartLocationProps } from './interface';
import { sendEvent } from '@/utils/analytics';
import { GeocoderService, LocationType, LocationService } from '@/maps';
import { useGeolocation } from '@/hooks';
import PlaceSearchDropdown from '@/components/PlaceSearchDropdown';

function StartLocation({ error, onPlaceSelect }: StartLocationProps): JSX.Element {
  const [geolocatedPlace, setGeolocatedPlace] = useState<LocationType | null>(null);
  const geolocation = useGeolocation();

  useEffect(() => {
    async function lookupLocation({ lat, lng }: { lat: number; lng: number }) {
      const location = await GeocoderService.reverse_geocode(new google.maps.LatLng(lat, lng));
      setGeolocatedPlace(LocationService.from_geocoder(location));
    }

    if (geolocation) {
      lookupLocation({ lat: geolocation.latitude, lng: geolocation.longitude });
    }
  }, [geolocation]);

  const handlePlaceSelect = (location: LocationType) => {
    sendEvent({
      category: 'Location',
      action: 'Starting Location Selected',
      label: location.address,
    });

    onPlaceSelect(location);
  };

  return (
    <PlaceSearchDropdown
      value={geolocatedPlace || undefined}
      error={error}
      label="Starting Here"
      onPlaceSelect={handlePlaceSelect}
    />
  );
}

export default StartLocation;
