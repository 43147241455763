export const INITIAL_PLACE_PREDICTION: google.maps.places.AutocompletePrediction = {
  description: '',
  matched_substrings: [],
  types: [],
  terms: [],
  place_id: 'INITIAL_PREDICTION_ID',
  structured_formatting: {
    main_text: '',
    main_text_matched_substrings: [],
    secondary_text: '',
  },
};
