import { LocationType } from './interface';

export class LocationService {
  static from_autocomplete(result: google.maps.places.AutocompletePrediction): LocationType {
    return {
      id: result.place_id,
      address: result.description,
    };
  }

  static to_automplete(result: LocationType): google.maps.places.AutocompletePrediction {
    return {
      description: result.address,
      matched_substrings: [],
      types: [],
      terms: [],
      place_id: result.id,
      structured_formatting: {
        main_text: '',
        main_text_matched_substrings: [],
        secondary_text: '',
      },
    };
  }

  static from_geocoder(result: google.maps.GeocoderResult): LocationType {
    return {
      id: result.place_id,
      address: result.formatted_address,
    };
  }
}
