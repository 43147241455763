import React, { useState } from 'react';

interface GoogleMapDirectionsProps {
  directions: google.maps.DirectionsResult | null;
  map?: google.maps.Map;
}

export function GoogleMapDirections({ directions, map }: GoogleMapDirectionsProps): JSX.Element {
  const [renderer, setRenderer] = useState<google.maps.DirectionsRenderer | null>(null);

  React.useEffect(() => {
    if (!renderer) {
      setRenderer(new google.maps.DirectionsRenderer());
      return undefined;
    }

    renderer.setMap(map!);

    return () => {
      if (renderer) {
        renderer.setMap(null);
      }
    };
  }, [renderer]);

  React.useEffect(() => {
    if (renderer && directions) {
      renderer.setDirections(directions);
    }
  }, [renderer, directions]);

  return <></>;
}
