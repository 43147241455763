import React from 'react';

import { MapProps } from './interface';
import GoogleMap, { GoogleMapDirections } from '@/components/GoogleMap';

function Map({ directions }: MapProps): JSX.Element {
  return (
    <div style={{ display: 'flex', height: 250 }}>
      <GoogleMap
        center={{ lat: 0, lng: 0 }}
        zoom={4}
        style={{ flexGrow: '1', height: '100%' }}
        streetViewControl={false}
        scaleControl={false}
        fullscreenControl={false}
        zoomControl={false}
        mapTypeControl={false}
      >
        <GoogleMapDirections directions={directions} />
      </GoogleMap>
    </div>
  );
}

export default Map;
