import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';

export function init() {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send('pageview');
}

export function sendEvent(payload: UaEventOptions) {
  ReactGA.event(payload);
}

const exports = {
  init,
  sendEvent,
};

export default exports;
