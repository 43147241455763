import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import TrueRate from '@/components/TrueRate';
import TrueRatePlaceholder from '@/components/TrueRate/Placeholder';
import { RatesContainerProps } from './interface';

function RatesContainer({ isLoading, error, rates }: RatesContainerProps) {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid item xs={12} md={6} sx={{ pb: 4 }}>
        <Typography component="h3" variant="h5">
          The real numbers
        </Typography>
      </Grid>
      {Boolean(error) && (
        <Grid item xs={12} sx={{ pb: 4 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid container item xs={12}>
        {isLoading
          ? Array.from({ length: 3 }).map((_, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx} sx={{ pr: 2 }}>
                <TrueRatePlaceholder />
              </Grid>
            ))
          : rates
              .filter((rate) => rate.true_rate)
              .map((rate, idx) => (
                <Grid item xs={12} sm={6} md={4} key={rate.id} sx={{ pr: 2 }}>
                  <TrueRate rate={rate} rank={idx + 1} />
                </Grid>
              ))}
      </Grid>
    </Paper>
  );
}

export default RatesContainer;
