import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Map from '@/components/Map';
import Number from '@/components/Number';
import TextWithHeader from '@/components/TextWithHeader';
import { TrueRateProps } from './interface';

function TrueRate({ rate, rank }: TrueRateProps): JSX.Element {
  return (
    <Card elevation={0} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <CardHeader title={`Rank #${rank}`} subheader={rate.title} sx={{ textAlign: 'center' }} />
      <CardContent>
        <Grid container justifyContent="space-between" wrap="wrap" sx={{ mb: 4 }}>
          <Grid item sx={{ pr: 1 }}>
            <TextWithHeader
              header="True RPM"
              text={
                <Number
                  value={rate.true_rate}
                  options={{
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }}
                />
              }
            />
          </Grid>
          <Grid item sx={{ pr: 1 }}>
            <TextWithHeader
              header="True Miles"
              text={
                <Number
                  value={rate.total_miles}
                  options={{
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }}
                />
              }
            />
          </Grid>
          <Grid item>
            <TextWithHeader header="Time" text={rate.time} />
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid container item>
            <Typography variant="caption">{rate.dho}</Typography>
          </Grid>
          <Grid container item justifyContent="center">
            <Typography variant="caption">{rate.pickup}</Typography>
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Typography variant="caption">{rate.dropoff}</Typography>
          </Grid>
        </Grid>
        <Stepper activeStep={-1}>
          <Step>
            <StepLabel StepIconProps={{ icon: 'A' }} />
          </Step>
          <Step>
            <StepLabel StepIconProps={{ icon: 'B' }} />
          </Step>
          <Step>
            <StepLabel StepIconProps={{ icon: 'C' }} />
          </Step>
        </Stepper>
        <Grid container wrap="nowrap" sx={{ mb: 4 }}>
          <Grid container item justifyContent="center">
            <Typography variant="caption">
              <Number
                value={rate.leg_distance[0]}
                options={{
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
                suffix="miles"
              />
            </Typography>
          </Grid>
          <Grid container item justifyContent="center">
            <Typography variant="caption">
              <Number
                value={rate.leg_distance[1]}
                options={{
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
                suffix="miles"
              />
            </Typography>
          </Grid>
        </Grid>
        <Map directions={rate.directions} />
      </CardContent>
    </Card>
  );
}

export default TrueRate;
